import React from 'react';
import classes from '../../css/styles.module.css';
import OurExperiences from './OurExperiences';
import Us from './Us';
import Contacto from './Contact';
import i18next from 'i18next';
import  SEO  from '../functions/MetaTags';

const Home = () => {

  const lang = i18next.language.split('-')[0] || 'en';
  
  const content = {
    en: {
      name: "EcoRolling",
      title: "Ecorolling | Fascinating Fauna",
      description: "As you explore the Coffee Region, you’ll come face-to-face with fascinating fauna" ,
      image: "https://eco-rolling.com/static/media/Home-hero-area-D.d98ae75486539318c38f.png",
      url: "https://dev.eco-rolling.com/ ",
      hero_title: "Fascinating Fauna",
      hero_desc: "As you explore the Coffee Region, you’ll come face-to-face with fascinating fauna.",
      col_hero_title: "Colombia",
      col_hero_desc: "Dreamy Landscapes: From the mountains rising on the horizon to the valleys dotted with coffee plantations, every view is a masterpiece of nature.",
    },
    es: {
      name: "EcoRolling",
      title: "Ecorolling | Fauna Fascinante",
      description: "A medida que exploras el Eje Cafetero, te encontrarás cara a cara con una fauna fascinante" ,
      image: "https://eco-rolling.com/static/media/Home-hero-area-D.d98ae75486539318c38f.png",
      url: "https://dev.eco-rolling.com/ ",
      hero_title: "Fauna Fascinante",
      hero_desc: "A medida que exploras el Eje Cafetero, te encontrarás cara a cara con una fauna fascinante.",
      col_hero_title: "Colombia",
      col_hero_desc: "Paisajes de Ensueño: Desde las montañas que se elevan en el horizonte hasta los valles salpicados de cafetales, cada vista es una obra maestra de la naturaleza.",
    }
  };
  
  const currentContent = content[lang] || content.en;

  return (
    <div>
    <SEO
        title={currentContent.title}
        description={currentContent.description}
        image={currentContent.image}
        name={currentContent.name}
    />
    <main>
      <div className={classes['main-content']}>
        <section className={`${classes['hero-area']} ${classes['flex-col']}`}>
          <h1 className={`${classes['hero-area-title']} ${classes.flex}`}>
            {content[lang].hero_title}
          </h1>
          <div className={`${classes['hero-area-desc']} ${classes.flex}`}>
            <p>{content[lang].hero_desc}</p>
          </div>
        </section>
        <OurExperiences />
        <section className={`${classes['hero-area-colombia']} ${classes['flex-col']}`}>
          <h2 className={`${classes['hero-area-title-col']} ${classes.flex}`}>
            <span className={classes.yellow}>Col</span>
            <span className={classes.blue}>om</span>
            <span className={classes.red}>bia</span>
          </h2>
          <div className={`${classes['hero-area-desc']} ${classes.flex}`}>
            <p>{content[lang].col_hero_desc}</p>
          </div>
        </section>
        <Us />
        <Contacto />
      </div>
    </main>
    </div>
  );
};

export default Home;
