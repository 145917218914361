import i18next from './i18n';

const validLanguages = ['en', 'es'];

export const getCurrentLanguage = () => {
  const defaultLanguage = 'en';

  if (!i18next.language) {
    return defaultLanguage;
  }

  const langCode = i18next.language.split('-')[0].toLowerCase();

  // Check if the language code is valid
  if (validLanguages.includes(langCode)) {
    return langCode;
  }

  return defaultLanguage;
};