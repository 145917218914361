import React from 'react';
import styles from './styles.module.css';

const Button = (props) => {
  const { children, ...params} = props;

  const styleVariants = {
    'main': 'main-btn'
  };

  const variant = 'btn-variant' in params? params['btn-variant']: 'main';

  const className = variant in styleVariants? styleVariants[variant]: styleVariants['main'];

  return(<button className={styles[className]}>{children}</button>);
};

export default Button;