import React from 'react';
import { Link } from 'react-router-dom';
import Button from "../button/Button";
import styles from './styles.module.css';

const StackedCardPlan = (props) => {
  const imgSource = 'img-source' in props ? props["img-source"]: '';
  const imgAlt = 'img-alt' in props ? props['img-alt'] : '';
  const title = 'title' in props ? props['title'] : '';
  const description = 'description' in props ? props['description'] : '';
  const actionUrl = 'action-url' in props ? props['action-url']: '';
  const actionText = 'action-text' in props ? props['action-text']: '';
  const testId = 'data-testid' in props ? props['data-testid']: '';

  return (
    <div  data-testid={testId} className={styles['card-plan']}>

      {imgSource !== '' &&
        <img src={imgSource} alt={imgAlt} />
      }

      {title !== '' &&
        <h2 className={styles['card-title']}>{title}</h2>
      }

      {description !== '' &&
        <p className={styles['card-description']}>{description}</p>
      }

      {actionUrl !== '' && actionText !== '' &&
      <Link to={actionUrl}>
        <Button>
          {actionText}
        </Button>
      </Link>
      }

    </div>
  );

};

export default StackedCardPlan;